import React, { useState } from 'react';
import "./einloggen.css"

interface einloggen {
    einmelden: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    verifyError: boolean;
}

const EinloggenView: React.FC<einloggen> = ({ einmelden, handleSubmit, verifyError }) => {
    return (
        <div className='container col d-flex justify-content-center align-items-center'>
            <div className='col-auto'>
                <div className='border p-5 rounded'>
                    <form onSubmit={handleSubmit}>
                        <label className="form-label">Passwort</label>
                        <input onChange={einmelden} name='password' type='password' className='form-control' />
                        <div className='text-center mt-4'>
                            <button type='submit' className='btn btn-danger rounded-4'>einloggen</button>
                        </div>
                        {verifyError && (
                            <div className="error-message mt-4">
                                Passwort ungültig
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EinloggenView;
