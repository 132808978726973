import React, { useState } from 'react';
import EinloggenView from './einloggenView';
import { useNavigate } from "react-router-dom";
import { login } from '../loginUserAWS';

const Einloggen: React.FC = () => {
    const navigate = useNavigate();
    const [verifyError, setVerifyError] = useState(false);

    const [nutzer, setNutzer] = useState<any>({
        username: process.env.REACT_APP_COGNITO_USER,
        password: ''
    });

    const einmelden = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNutzer((antes: any) => ({
            ...antes,
            [name]: value
        }));
    };

    const verify = async (e: React.FormEvent<HTMLFormElement>): Promise<boolean> => {
        e.preventDefault();
        try {
            const normal = "normal";
            await login(nutzer.username, nutzer.password);
            navigate("/start", { state: { normal } });
            return true;
        } catch (err) {
            console.log({ message: "error by authentication", err });
            return false;
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        verify(event).then((result: boolean) => {
            if (!result) {
                setVerifyError(true);
            }
        });
    };

    return (
        <EinloggenView
            einmelden={einmelden}
            handleSubmit={handleSubmit}
            verifyError={verifyError}
        />
    );
};

export default Einloggen;
